import Session from 'supertokens-auth-react/recipe/session'
import { API_ENDPOINT } from 'src/consts/env'
import {
  API_CHANGE_PASSWORD,
  API_SUBSCRIBE,
  API_SUBSCRIBE_EXIST,
  API_USER_DETAILS,
  API_USER_AVATAR,
  API_DELETE_USER,
  API_USER_POINTS,
  API_SAVE_USER_TRANSACTION,
  API_X_USER_POINTS,
  API_SAVE_X_USER_TRANSACTION,
} from 'src/consts'
import http from 'src/services/http'

export async function deleteUserAccount() {
  const session = await Session.doesSessionExist()
  if (session) {
    return await http.delete({ url: `${API_ENDPOINT}${API_DELETE_USER}` })
  } else {
    return false
  }
}

export async function changePassword(params: any) {
  const url = `${API_ENDPOINT}${API_CHANGE_PASSWORD}`
  const session = await Session.doesSessionExist()
  if (session) {
    return http.post({
      url: url,
      params: params,
    })
  } else {
    return false
  }
}

export async function checkSubscribe(): Promise<boolean> {
  const url = `${API_ENDPOINT}${API_SUBSCRIBE_EXIST}` // Ensure the URL is correctly formatted
  const session = await Session.doesSessionExist()
  if (session) {
    const response = await http.get({ url: url })
    return Boolean(response)
  } else {
    return false
  }
}

export async function changeSubscribe(subscribe: boolean) {
  const url = `${API_ENDPOINT}${API_SUBSCRIBE}`
  const session = await Session.doesSessionExist()

  if (session) {
    try {
      let response = null
      if (subscribe) {
        response = await http.post({ url })
      } else {
        response = await http.delete({ url })
      }
      return response
    } catch (error) {
      return false
    }
  } else {
    return false
  }
}

export async function saveUserDetails(userData) {
  const url = `${API_ENDPOINT}${API_USER_DETAILS}`
  const session = await Session.doesSessionExist()

  if (session) {
    try {
      const result = await http.post({
        url: url,
        params: userData,
      })
      return result
    } catch (error) {
      throw error
    }
  }
}

export async function saveUserAvatar(avatarUrl: string) {
  const url = `${API_ENDPOINT}${API_USER_DETAILS}`
  const session = await Session.doesSessionExist()

  const avatarData = {
    avatarUrl,
  }

  if (session) {
    try {
      const result = await http.post({
        url: url,
        params: avatarData,
      })
      return result
    } catch (error) {
      throw error
    }
  }
}

export async function getUserDetails(): Promise<any> {
  const url = `${API_ENDPOINT}${API_USER_DETAILS}`
  const session = await Session.doesSessionExist()

  if (session) {
    return http.get({ url: url, params: { populate: 'settings' } })
  } else {
    // Handle the scenario when there is no session, e.g., redirect to login or return null
    return null
  }
}

export async function getUserPoints(): Promise<any> {
  const session = await Session.doesSessionExist()
  if (session) {
    return http.get({ url: `${API_ENDPOINT}${API_USER_POINTS}` })
  } else {
    throw new Error('user is not authorized')
  }
}

export async function saveUserTransaction(
  txId: string,
  amount: number
): Promise<any> {
  const session = await Session.doesSessionExist()
  if (session) {
    return http.post({
      url: `${API_ENDPOINT}${API_SAVE_USER_TRANSACTION}`,
      params: { txId, amount },
    })
  } else {
    throw new Error('user is not authorized')
  }
}

export async function getAnonymUserPoints(): Promise<any> {
  return http.get({ url: `${API_ENDPOINT}${API_X_USER_POINTS}` })
}

export async function saveAnonymUserTransaction(
  txId: string,
  amount: number
): Promise<any> {
  return http.post({
    url: `${API_ENDPOINT}${API_SAVE_X_USER_TRANSACTION}`,
    params: { txId, amount },
  })
}

// export async function getUserAvatar(): Promise<any> {
//   const url = `${API_ENDPOINT}${API_USER_DETAILS}`
//   return http.get({ url: url })
// }

// export async function saveUserAvatar(file: File): Promise<any> {
//   const url = `${API_ENDPOINT}${API_USER_AVATAR}`
//   const formData = new FormData()
//   formData.append('image', file) // 'image' should match the expected key on the server side

//   try {
//     const response = await http.post({
//       url: url,
//       formData: formData,
//     })
//     return response
//   } catch (error) {
//     throw error
//   }
// }
