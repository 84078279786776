import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RawIntlProvider } from 'react-intl'
import { SWRConfig } from 'swr'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { OverlayProvider } from '@react-aria/overlays'
import { SSRProvider } from '@react-aria/ssr'
import SuperTokensReact, { SuperTokensWrapper } from 'supertokens-auth-react'
import { wrapper } from 'src/redux/store'
import { intl } from 'src/i18n'
import { Toast } from 'src/components/Toast'
import { ENTITY } from 'src/consts'
import { setCurrentEntity } from 'src/redux/actions'
import { EventTracker } from 'src/elements'
import { ErrorBoundaryRefresh } from 'src/elements'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils/localStorage'
import { setSharedCurrency } from 'src/redux/thunks'
import { returnParamFromUrl } from 'src/360ui/utils/router'
import { knownCurrencies } from 'src/consts'
import { EmailVerificationMessage } from 'src/components/Auth'
import 'src/styles/css/index.scss'
import { frontendConfig } from '../config/frontendConfig'
import { checkAuthStatus } from 'src/redux/thunks/auth'
import { SessionExpireModal } from 'src/components/SessionExpireModal'

const CookiesBanner = dynamic(() => import('src/components/CookiesBanner'), {
  ssr: false,
})

const FormBanner = dynamic(
  () => import('src/components/GoogleForm/components/FormBanner'),
  {
    ssr: false,
  }
)

const StickyCorner = dynamic(
  () => import('src/components/Banners/StickyCorner'),
  { ssr: false }
)
const TopLeaderboardBanner = dynamic(
  () => import('src/components/Banners/TopLeaderboardBanner'),
  { ssr: false }
)

if (typeof window !== 'undefined') {
  SuperTokensReact.init(frontendConfig())
}

export const adButlerScripts = `
  if (!window.AdButler) {
    (function() {
      var s = document.createElement("script");
      s.async = true;
      s.type = "text/javascript";
      s.src = 'https://servedbyadbutler.com/app.js';
      s.onload = function() { window.canShowAd = true }
      s.onerror = function() { window.canShowAd = false }
      var n = document.getElementsByTagName("script")[0];
      n.parentNode.insertBefore(s, n);
    }());
  }
  var AdButler = AdButler || {};
  AdButler.ads = AdButler.ads || [];
  var abkw = window.abkw || '';

  fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(res => { window.userIP = res.ip })
`

const DefaultLayout = ({ children }) => children

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const isWidget = router.route.split('/')[1].includes('widget')
  const { isAuth } = useSelector(state => state.auth)
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false)

  useEffect(() => {
    const { currentEntityByLocation } = pageProps
    dispatch(setCurrentEntity(currentEntityByLocation))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])
  useEffect(() => {
    dispatch(checkAuthStatus())
  }, [dispatch])

  useEffect(() => {
    const currency =
      returnParamFromUrl('currency') || getLocalStorageItem('currency')

    const preparedCurrency = knownCurrencies[currency] ? currency : 'USD'

    if (preparedCurrency !== 'USD') {
      dispatch(setSharedCurrency(preparedCurrency, true))
    }
    setLocalStorageItem('currency', currency)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const swrConfig = pageProps.fallback
    ? { fallback: pageProps.fallback }
    : undefined

  const Layout = Component.getLayout || DefaultLayout

  return (
    <SuperTokensWrapper
      onSessionExpired={() => {
        setSessionExpiredModal(true)
      }}
    >
      <SSRProvider>
        <OverlayProvider id='overlay-provider'>
          <ErrorBoundaryRefresh>
            <SWRConfig value={swrConfig}>
              <RawIntlProvider value={intl}>
                <Script
                  id='adButlerScripts'
                  strategy='beforeInteractive'
                  dangerouslySetInnerHTML={{ __html: adButlerScripts }}
                />
                <EventTracker />
                {!isWidget && <TopLeaderboardBanner />}
                <Layout {...pageProps}>
                  <Component {...pageProps} />
                </Layout>
                <Head>
                  <meta name='renderedAt' content={new Date().toISOString()} />
                </Head>
                <Toast />
                {/* {
                  <SessionExpireModal
                    openModal={sessionExpiredModal}
                    setOpenModal={setSessionExpiredModal}
                  />
                } */}
                <EmailVerificationMessage />
                {!isWidget && (
                  <>
                    {isAuth && <FormBanner />}
                    <CookiesBanner />
                    <StickyCorner position={'left'} />
                    <StickyCorner position={'right'} />
                  </>
                )}
              </RawIntlProvider>
            </SWRConfig>
          </ErrorBoundaryRefresh>
        </OverlayProvider>
      </SSRProvider>
    </SuperTokensWrapper>
  )
}

MyApp.getInitialProps = ({ router }) => {
  const currentEntityByLocation = router.asPath.includes('exchange')
    ? ENTITY.EXCHANGE
    : ENTITY.COIN

  return {
    pageProps: {
      currentEntityByLocation,
    },
  }
}

export default wrapper.withRedux(MyApp)
