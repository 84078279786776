export const API_GET_GRAPH = '/graph'
export const API_NFT_COLLECTIONS = '/site-api/nft/collections'
export const API_NFT_BLOCKCHAINS = '/site-api/nft/blockchain/all'
export const API_GET_MAPDATA = '/site-api/coins'
export const API_GET_COINS_LIST = '/site-api/coins/list'
export const API_GET_COINS_STATS = '/site-api/coins/stats'
export const API_GET_MAPDATA_MARKETS = '/site-api/coins/:slug/markets'
export const API_GET_EXCHANGES_MAPDATA = '/site-api/exchanges'
export const API_GET_EXCHANGES_MAPDATA_MARKETS =
  '/site-api/exchanges/:slug/markets'
export const API_GET_MARKET_OPTIONS = '/site-api/market_data/markets_map'
export const API_GET_GLOBAL_STATS = '/site-api/global/stats'
export const API_GLOBAL_SEARCH = '/site-api/global/search'
export const API_GET_WATCHLIST = '/site-api/watchlist'
export const API_USER_DETAILS = `/site-api/user/details`
export const API_CHANGE_PASSWORD = '/site-api/user/change-password'
export const API_DELETE_USER = '/site-api/user/'
export const API_USER_AVATAR = '/site-api/user/avatar'
export const API_USER_POINTS = '/site-api/user/points'
export const API_SAVE_USER_TRANSACTION = '/site-api/user/transactions'
export const API_X_USER_POINTS = '/site-api/user/x/points'
export const API_SAVE_X_USER_TRANSACTION = '/site-api/user/x/transactions'
export const API_SUBSCRIBE_EXIST = '/site-api/emails/exists'
export const API_SUBSCRIBE = '/site-api/emails'
export const API_AUTHOR = '/api/authors'
export const API_EVENTS = '/api/events'
export const API_GLOSSARY = '/api/glossaries'
export const API_GLOSSARY_INDEX = '/api/glossaries-index'
export const API_LIST = '/api/lists'
export const API_LIST_INDEX = '/api/lists-index'
export const API_LIST_ITEM = '/api/list-items'
export const API_LIST_PINNED = '/api/list-pinned'
export const API_LIST_CONTENT = '/api/list-content'
export const API_REVIEW_CONTENT = '/api/review-content'
export const API_NEWS_ARTICLE_PINNED = '/api/new-pinned'
export const API_NEWS_ARTICLES = '/api/news'
export const API_NEWS_ARTICLE = '/api/news'
export const API_NEWSLETTERS = '/api/newsletters'
export const API_GET_SPREADS_MARKET = '/api/orderbook_liquidity?market='
export const API_PRESS_RELEASES = '/api/press-releases'
export const API_PRESS_RELEASE_BACKEND = '/api/releases'
export const API_GET_SCREENSHOT = '/api/share'
export const API_TAGS = '/api/tags'
export const API_REVIEWS = '/api/reviews'
export const API_RATINGS = '/api/rating-attributes'
export const API_AVATARS = '/api/avatars'
export const API_LEARN_ARTICLES = '/api/learn-articles'
